import React from "react";
import "./content.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import contact_btn from "../../assets/contact_btn.svg";

const Content = (props) => {
    const navigate = useNavigate();
    return (
        <div className="bg" style={{ backgroundImage: `url(${props?.bg})` }}>
            <motion.div
                initial={{
                    opacity: 0,
                    y: 100,
                    x: props.num === 2 ? "-80%" : props.num === 3 ? "80%" : 0,
                }}
                whileInView={{
                    opacity: 1,
                    y: -30,
                    x: props.num === 2 ? "-80%" : props.num === 3 ? "80%" : 0,
                }}
                transition={{ duration: 1, delay: 0.25 }}
                exit={{ opacity: 0 }}
            >
                {props.num === 4 ? (
                    <img
                        src={contact_btn}
                        style={{ cursor: "pointer" }}
                        alt="Kingsmen Motors"
                        onClick={() => navigate("/contact")}
                    />
                ) : (
                    <div
                        className="text_onbg"
                        style={{
                            width:
                                props.num === 1
                                    ? "38vw"
                                    : props.num === 2
                                    ? "24vw"
                                    : "27vw",
                            textAlign: props.num === 1 ? "center" : "left",
                            alignItems: props.num === 1 ? "center" : null,
                        }}
                    >
                        <p className="heading">{props.title}</p>
                        <p>{props.para}</p>
                    </div>
                )}
            </motion.div>
        </div>
    );
};

export default Content;
