import React from "react";
import "./navbar.css";
import kingsmenLogo from "../../assets/kingsmenLogo.svg";
import kingsmen_arabic from "../../assets/kingsmen_arabic.svg";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
    const navigate = useNavigate();
    return (
        <div className="nav">
            <img
                src={kingsmenLogo}
                className="logo"
                alt="kingsmen motors"
                onClick={() => navigate("/")}
            />
            <img
                src={kingsmen_arabic}
                className="logo"
                alt="kingsmen motors"
                onClick={() => navigate("/")}
            />
        </div>
    );
};

export default Navbar;
