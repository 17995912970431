import React from "react";
import kingsmen_2030 from "../../assets/kingsmen_2030.svg";
import "./contact.css";
import Footer from "../footer/Footer";
const Contact = () => {
    return (
        <div className="contact_wrapper">
            <div className="top">
                <div className="gradient_container">
                    <p className="gradient-text">COMING SOON</p>
                </div>
                <img src={kingsmen_2030} className="vision" alt="kingsmen" />
            </div>
            <div className="bottom">
                <Footer />
            </div>
        </div>
    );
};

export default Contact;
