import React from "react";
import { motion } from "framer-motion";
import kingsmen_green from "../../assets/kingsmen_green.jpg";
import kingsmen_car_top from "../../assets/kingsmen_car_top.png";
import kingsmen_mobile_desert from "../../assets/kingsmen_mobile_desert.jpg";
import kingsmen_bright_bg from "../../assets/kingsmen_bright_bg.jpg";
import { paras, mobileTitles } from "../explore/paras";
import MobileContent from "./MobileContent";
import "./mobileExplore.css";

const MobileExplore = () => {
    return (
        <div className="mobile_explore">
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.25 }}
            >
                <MobileContent
                    para={paras.paraFirst}
                    paraBroken={paras.paraBroken}
                    num={1}
                    bg={kingsmen_green}
                    title={mobileTitles.title1}
                />
                <MobileContent
                    para={paras.para2}
                    num={2}
                    bg={kingsmen_bright_bg}
                    title={mobileTitles.title2}
                />
                <MobileContent
                    para={paras.para3}
                    num={3}
                    bg={kingsmen_mobile_desert}
                    title={mobileTitles.title3}
                />
                <MobileContent para={paras.para4} num={4} />
            </motion.div>
            <img
                src={kingsmen_car_top}
                alt="kingsmen motors"
                className="kingsmen_mobile_car"
            />
        </div>
    );
};

export default MobileExplore;
