import React from "react";
import "./footer.css";
import kingsmenLogo from "../../assets/kingsmenLogo.svg";
import kingsmen_facebook from "../../assets/kingsmen_facebook.svg";
import kingsmen_linkedin from "../../assets/kingsmen_linkedin.svg";
import kingsmen_twitter from "../../assets/kingsmen_twitter.svg";
const Footer = () => {
    return (
        <footer>
            <div className="divider">
                <div className="left-section">
                    <div className="logo">
                        <img
                            src={kingsmenLogo}
                            className="logo"
                            alt="Kingsmen Motors"
                        />
                    </div>
                    <div className="contact">
                        <p style={{ fontSize: 14, fontWeight: "700" }}></p>
                        <p style={{ fontSize: 12 }}>
                            support@kingsmenmotors.com
                        </p>
                    </div>
                </div>
                <div className="right-section">
                    <div className="subscribe">
                        <p className="notify">Notify me</p>
                        <p className="desc">
                            Stay updated with our activities.
                        </p>
                    </div>
                    <div class="input-group mt-2">
                        <input
                            type="text"
                            class="form-control "
                            placeholder="Your Email"
                            aria-label="Message"
                        />
                        <button class="btn btn-dark" type="button">
                            Send
                        </button>
                    </div>
                </div>
            </div>

            <div className="bottom-section">
                <div className="social-icons">
                    <img src={kingsmen_facebook} alt="Kingsmen Motors" />
                    <img src={kingsmen_linkedin} alt="Kingsmen Motors" />
                    <img src={kingsmen_twitter} alt="Kingsmen Motors" />
                </div>
                <p style={{ fontSize: 14, margin: 0 }}>
                    © 2023 Kingsmen Motors. All rights reserved
                </p>
            </div>
        </footer>
    );
};

export default Footer;
