import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import contact_btn from "../../assets/contact_btn.svg";
import "./mobileContent.css";
const MobileContent = (props) => {
    const navigate = useNavigate();
    return (
        <div
            className="mobile_bg"
            style={{ backgroundImage: `url(${props?.bg})` }}
        >
            <motion.div
                initial={{
                    opacity: 0,
                    y: props.num === 4 ? 200 : 135,
                    x: props.num === 4 ? 0 : -50,
                }}
                whileInView={{
                    opacity: 1,
                    y: props.num === 4 ? -200 : -135,
                    x: props.num === 4 ? 0 : -50,
                }}
                transition={{ duration: 1.5, delay: 0.25 }}
                exit={{ opacity: 0 }}
            >
                {props.num === 4 ? (
                    <div style={{ width: "300px", textAlign: "center" }}>
                        <p className="para4_text">{props.para}</p>
                        <img
                            src={contact_btn}
                            style={{ cursor: "pointer" }}
                            alt="Kingsmen Motors"
                            onClick={() => navigate("/contact")}
                        />
                    </div>
                ) : (
                    <div className="mobile_text_onbg">
                        <p className="heading">
                            {props.title.split("\n").map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </p>
                        <p>{props.para}</p>
                        {props.num === 1 ? <p>{props.paraBroken} </p> : null}
                    </div>
                )}
            </motion.div>
        </div>
    );
};

export default MobileContent;
