import "./App.css";
import Scene from "./components/scene/Scene";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout/Layout";
import Contact from "./components/contact/Contact";
import Explore from "./components/explore/Explore";
import useMobileDetect from "./hooks/useMobileDetect";
import MobileExplore from "./components/mobileExplore/MobileExplore";

const App = () => {
    const isMobile = useMobileDetect();
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    {isMobile ? (
                        <>
                            <Route path="/" element={<MobileExplore />} />
                            <Route path="/contact" element={<Contact />} />
                        </>
                    ) : (
                        <>
                            <Route path="/" element={<Scene />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/explore" element={<Explore />} />
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
