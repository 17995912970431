import React, { useEffect, useState, useRef } from "react";
import "./scene.css";
import { useNavigate } from "react-router-dom";
import kingsmenmp4 from "../../assets/kingsmenmp4.mp4";
import animationThumbnail from "../../assets/static.png";
import scroll_icon from "../../assets/scroll_icon.gif";
import useMobileDetect from "../../hooks/useMobileDetect";
import explore_button from "../../assets/explore_btn.svg";

const Scene = () => {
    const [animateToBottom, setAnimateToBottom] = useState(false);
    const [scrollHide, setScrollHide] = useState(false);
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const isMobile = useMobileDetect();

    useEffect(() => {
        const handleScroll = () => {
            playVideo();
        };

        window.addEventListener("wheel", handleScroll);

        return () => {
            window.removeEventListener("wheel", handleScroll);
        };
    }, []);

    const playVideo = () => {
        videoRef.current.play();
        setScrollHide(true);
    };

    const doNothing = () => {};

    return (
        <div className="wrapper">
            <div
                className={
                    animateToBottom
                        ? "explore_button_faded_visible"
                        : "explore_button_faded"
                }
            >
                <img
                    src={explore_button}
                    alt="Kingsmen Motors"
                    onClick={() => navigate("/explore")}
                    style={{
                        width: "80%",
                        height: "40%",
                        cursor: "pointer",
                        objectFit: "contain",
                    }}
                />
            </div>
            <video
                onClick={() => {
                    isMobile ? playVideo() : doNothing();
                }}
                poster={animationThumbnail}
                id={`animation_img${animateToBottom ? "_on" : ""}`}
                ref={videoRef}
                src={kingsmenmp4}
                type="video/mp4"
                muted
                controls={false}
                playsInline
                onEnded={() => setAnimateToBottom(true)}
                autoPlay={isMobile ? true : false}
            />
            <div className={`info_guide${scrollHide ? "_hide" : ""}`}>
                <img
                    src={scroll_icon}
                    className="scroll_icon"
                    alt="kingsmen motors"
                />
                <p style={{ opacity: 0.5, fontSize: 12 }}>SCROLL</p>
            </div>
        </div>
    );
};

export default Scene;
