export let paras = {
    para1: "At Kingsmen Motors, our vision is to reignite the spirit of the Islamic Golden Age and redefine the pinnacle of automotive excellence by crafting luxury supercars that embody the fusion of heritage and cutting-edge technology. Inspired by the wisdom, craftsmanship, and artistic achievements of the rich history of the Muslim inventors, we are dedicated to capturing the hearts of the region's discerning elite.",
    para2: "A masterpiece of automotive engineering designed to offer an unmatched driving experience both on the city streets and the racetracks. This supercar is the epitome of versatility, combining luxurious comfort with heart-pounding performance, delivering an adrenaline-fueled journey wherever you choose to go.",
    para3: "Designed to defy boundaries and conquer the sand dunes of the desert. Built with off-road prowess and exhilarating performance, this exceptional machine allows you to unleash your adventurous spirit and experience the thrill of off-roading in the most challenging terrains.",
    paraFirst:
        "At Kingsmen Motors, our vision is to reignite the spirit of the Islamic Golden Age and redefine the pinnacle of automotive excellence by crafting luxury supercars that embody the fusion of heritage and cutting-edge technology.",
    paraBroken:
        "Inspired by the wisdom, craftsmanship, and artistic achievements of the rich history of the Muslim inventors, we are dedicated to capturing the hearts of the region's discerning elite.",
    para4: "LEARN MORE ABOUT OUR VISION",
};

export let titles = {
    title1: "KINGSMEN MOTORS",
    title2: "THE RACE HORSE",
    title3: "THE DESERT STORM",
};

export let mobileTitles = {
    title1: "KINGSMEN\nMOTORS",
    title2: "THE\nRACE\nHORSE",
    title3: "THE\nDESERT\nSTORM",
};
