import React from "react";
import { motion } from "framer-motion";
import Content from "../content/Content";
import kingsmen_desert from "../../assets/kingsmen_desert.jpg";
import kingsmen_skyline from "../../assets/kingsmen_skyline.jpg";
import kingsmen_saudi from "../../assets/kingsmen_saudi.jpg";
import kingsmen_car from "../../assets/kingsmen_car.png";
import "./explore.css";
import { paras, titles } from "./paras";

const Explore = () => {
    return (
        <div className="explore">
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.25 }}
            >
                <Content
                    para={paras.para1}
                    num={1}
                    bg={kingsmen_saudi}
                    title={titles.title1}
                />
                <Content
                    para={paras.para2}
                    num={2}
                    bg={kingsmen_skyline}
                    title={titles.title2}
                />
                <Content
                    para={paras.para3}
                    num={3}
                    bg={kingsmen_desert}
                    title={titles.title3}
                />
                <Content num={4} />
            </motion.div>
            <img src={kingsmen_car} alt="kingsmen" className="car_img" />
        </div>
    );
};

export default Explore;
