import { useState, useEffect } from "react";

const useMobileDetect = () => {
    const [isMobile, setIsMobile] = useState(false);

    const checkIsMobile = () => {
        const windowWidth = window.innerWidth;
        const mobileBreakpoint = 768; // Adjust this value as needed

        setIsMobile(windowWidth < mobileBreakpoint);
    };

    useEffect(() => {
        checkIsMobile();

        const handleResize = () => {
            checkIsMobile();
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return isMobile;
};

export default useMobileDetect;
